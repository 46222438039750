import React from 'react';
import { Helmet } from 'react-helmet';
import './Gallery.css';
import { motion } from 'framer-motion';
import MenuSlider from '../../Components/MenuSlider/MenuSlider';
import imagesgallery from '../../Components/Assests/Assests';

const images = [
  { id: 1, src: imagesgallery.galleryimg1, alt: 'Catering for Birthday Event in Bhiwandi' },
  { id: 2, src: imagesgallery.galleryimg2, alt: 'Wedding Catering Service in Bhiwandi' },
  { id: 3, src: imagesgallery.galleryimg3, alt: 'Pooja Ceremony Catering in Bhiwandi' },
  { id: 4, src: imagesgallery.galleryimg4, alt: 'Corporate Event Catering in Bhiwandi' },
  { id: 5, src: imagesgallery.galleryimg5, alt: 'Anniversary Catering in Bhiwandi' },
  { id: 6, src: imagesgallery.galleryimg6, alt: 'Engagement Catering in Bhiwandi' },
  { id: 7, src: imagesgallery.galleryimg7, alt: 'Housewarming Catering in Bhiwandi' },
  { id: 8, src: imagesgallery.galleryimg8, alt: 'Festival Catering in Bhiwandi' },
  { id: 9, src: imagesgallery.galleryimg9, alt: 'Outdoor Catering Services in Bhiwandi' },
  { id: 10, src: imagesgallery.galleryimg10, alt: 'Large Event Catering in Bhiwandi' },
  { id: 11, src: imagesgallery.galleryimg11, alt: 'Small Gathering Catering in Bhiwandi' },
  { id: 12, src: imagesgallery.galleryimg12, alt: 'Exclusive Catering for Family Events in Bhiwandi' },
  { id: 13, src: imagesgallery.galleryimg13, alt: 'Bridal Shower Catering in Bhiwandi' },
  { id: 14, src: imagesgallery.galleryimg14, alt: 'Corporate Lunch Catering in Bhiwandi' },
  { id: 15, src: imagesgallery.galleryimg15, alt: 'Special Catering for Religious Events in Bhiwandi' },
  { id: 16, src: imagesgallery.galleryimg16, alt: 'Buffet Catering for Events in Bhiwandi' },
  { id: 17, src: imagesgallery.galleryimg17, alt: 'Customized Catering Menu in Bhiwandi' },
  { id: 18, src: imagesgallery.galleryimg18, alt: 'Vegetarian Catering for Weddings in Bhiwandi' },
  { id: 19, src: imagesgallery.galleryimg19, alt: 'Vegetarian Catering Service in Bhiwandi' },
  { id: 20, src: imagesgallery.galleryimg20, alt: 'Dessert Catering for Events in Bhiwandi' },
  { id: 21, src: imagesgallery.galleryimg21, alt: 'Catering for Business Meetings in Bhiwandi' },
  { id: 22, src: imagesgallery.galleryimg22, alt: 'Traditional Food Catering in Bhiwandi' },
  { id: 23, src: imagesgallery.galleryimg23, alt: 'Specialized Catering for Festive Occasions in Bhiwandi' },
  { id: 24, src: imagesgallery.galleryimg24, alt: 'Luxury Catering Service in Bhiwandi' },
  { id: 25, src: imagesgallery.galleryimg25, alt: 'Food Catering for Large Weddings in Bhiwandi' },
];

const Gallery = () => {
  return (
    <>
      <Helmet>
        <title>Event Gallery | Ashapura Caterers | Catering Services in Bhiwandi, Thane</title>
        <meta
          name="description"
          content="Explore our event gallery showcasing catering services by Ashapura Caterers for weddings, corporate events, festivals, and more in Bhiwandi, Thane, Maharashtra."
        />
        <meta
          name="keywords"
          content="Ashapura Caterers, catering services Bhiwandi, event catering Thane, wedding catering Bhiwandi, corporate catering Bhiwandi, festival catering Thane, Maharashtra"
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="Event Gallery | Ashapura Caterers" />
        <meta
          property="og:description"
          content="View a wide range of catering services by Ashapura Caterers, including weddings, corporate events, festivals, and more in Bhiwandi, Thane."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ashapuracaterers.in/gallery" />
        <meta property="og:image" content="https://ashapuracaterers.in/path-to-image.jpg" />
        <link rel="canonical" href="https://ashapuracaterers.in/gallery" />
      </Helmet>

      <MenuSlider />
      <div className="gallery-container">
        <h1>Event Gallery</h1>
        <div className="gallery-grid">
          {images.map((image) => (
            <motion.div
              key={image.id}
              className="gallery-image"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: image.id * 0.1 }} // Staggered animation
              whileHover={{ scale: 1.05 }} // Hover animation
            >
              <img src={image.src} alt={image.alt} />
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Gallery;
