import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Preloader.css'
const Preloader = ({ onLoaded }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
      onLoaded();
    }, 3000); 

    return () => clearTimeout(timer);
  }, [onLoaded]);

  const colors = ['#FF3C38', '#FF9A3C', '#FFD700'];
  const letters = ['A', 'C', 'S'];

  const pathVariants = {
    initial: { pathLength: 0, opacity: 0 },
    animate: (i) => ({
      pathLength: 1,
      opacity: 1,
      transition: {
        pathLength: { type: 'spring', duration: 1.5, bounce: 0, delay: i * 0.5 },
        opacity: { duration: 0.01 }
      }
    })
  };

  const letterVariants = {
    initial: { scale: 0, opacity: 0 },
    animate: (i) => ({
      scale: 1,
      opacity: 1,
      transition: {
        type: 'spring',
        duration: 1,
        bounce: 0.3,
        delay: 1.5 + i * 0.2
      }
    })
  };

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div 
          className="fixed inset-0 flex items-center justify-center bg-white overflow-hidden"
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          <div className="relative w-full h-full max-w-[300px] max-h-[300px]">
            {/* Circular Border Animation */}
            <motion.div
              className="absolute inset-0 border-[5px] border-[#FF3C38] border-t-transparent rounded-full"
              animate={{ rotate: 360 }}
              transition={{ duration: 2, repeat: Infinity, ease: "linear" }}
            />
            
            <svg viewBox="0 0 100 100" className="w-full h-full">
              <defs>
                {colors.map((color, i) => (
                  <motion.path
                    key={i}
                    id={`waterpathid${i}`}
                    d={`M ${-20 + i * 10},50 C 20,${30 + i * 10} 50,${70 - i * 10} 100,50`}
                    fill="none"
                    stroke={color}
                    strokeWidth="0.5"
                    variants={pathVariants}
                    initial="initial"
                    animate="animate"
                    custom={i}
                  />
                ))}
              </defs>
              
              {colors.map((color, i) => (
                <motion.text
                  key={i}
                  dy={i === 1 ? 1 : 0}
                  fill={color}
                  fontSize="10"
                  fontWeight="bold"
                  variants={letterVariants}
                  initial="initial"
                  animate="animate"
                  custom={i}
                >
                  <textPath href={`#waterpathid${i}`} startOffset="50%">
                    {letters[i]}
                  </textPath>
                </motion.text>
              ))}
            </svg>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Preloader;