import video1 from './Video/video1.mp4'
import leafdesigns from './images/groupall.png'
import specialleaf1 from './images/Group-2.png'
import specialleaf2 from './images/Group-3.png'
import pannermenu from './images/Shahi-Paneer-1.jpg'
import marriage from './images/marriage.jpeg'
import reviewspan from './images/Reviews.png'
import chefbotimg from './images/chefchatbot.png'

import galleryimg1 from './images/BirthdayImage/b1.jpg'
import galleryimg2 from './images/BirthdayImage/b2.jpg'
import galleryimg3 from './images/BirthdayImage/b3.jpg'
import galleryimg4 from './images/BirthdayImage/b4.jpg'
import galleryimg5 from './images/BirthdayImage/b5.jpg'
import galleryimg6 from './images/BirthdayImage/b6.jpg'
import galleryimg7 from './images/BirthdayImage/b7.jpg'
import galleryimg8 from './images/BirthdayImage/b8.jpg'
import galleryimg9 from './images/BirthdayImage/b9.jpg'
import galleryimg10 from './images/BirthdayImage/b10.jpg'
import galleryimg11 from './images/BirthdayImage/b11.jpg'
import galleryimg12 from './images/BirthdayImage/b12.jpg'
import galleryimg13 from './images/BirthdayImage/b13.jpg'
import galleryimg14 from './images/BirthdayImage/b14.jpg'
import galleryimg15 from './images/BirthdayImage/b15.jpg'
import galleryimg16 from './images/BirthdayImage/b16.jpg'
import galleryimg17 from './images/BirthdayImage/b17.jpg'
import galleryimg18 from './images/BirthdayImage/b18.jpg'
import galleryimg19 from './images/BirthdayImage/b19.jpg'
import galleryimg20 from './images/BirthdayImage/b20.jpg'
import galleryimg21 from './images/BirthdayImage/b21.jpg'
import galleryimg22 from './images/BirthdayImage/b22.jpg'
import galleryimg23 from './images/BirthdayImage/b23.jpg'
import galleryimg24 from './images/BirthdayImage/b24.jpg'
import galleryimg25 from './images/BirthdayImage/b25.jpg'

export default {
    video1,
    leafdesigns,
    specialleaf1,
    specialleaf2,
    pannermenu,
    marriage,
    reviewspan,
    chefbotimg,
    galleryimg1,
    galleryimg2,
    galleryimg3,
    galleryimg4,
    galleryimg5,
    galleryimg6,
    galleryimg7,
    galleryimg8,
    galleryimg9,
    galleryimg10,
    galleryimg11,
    galleryimg12,
    galleryimg13,
    galleryimg14,
    galleryimg15,
    galleryimg16,
    galleryimg17,
    galleryimg18,
    galleryimg19,
    galleryimg20,
    galleryimg21,
    galleryimg22,
    galleryimg23,
    galleryimg24,
    galleryimg25,
}