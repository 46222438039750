import React from 'react';
import { motion } from 'framer-motion';
import './Contact.css';

const Contact = () => {
  const formVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8, ease: 'easeOut' }
    }
  };

  return (
    <div className="contact-container">
      <motion.div
        className="contact-content"
        initial="hidden"
        animate="visible"
        variants={formVariants}
      >
        <h1 className="contact-title">Get in Touch with Us</h1>
        <p className="contact-description">
          We’re here to assist you with any questions or inquiries you may have. Please fill out the form below, and we’ll get back to you as soon as possible.
        </p>
        <form className="contact-form">
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" required />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Phone Number</label>
            <input type="tel" id="phone" name="phone" />
          </div>
          <div className="form-group">
            <label htmlFor="subject">Subject</label>
            <input type="text" id="subject" name="subject" required />
          </div>
          <div className="form-group">
            <label htmlFor="inquiry-type">Type of Inquiry</label>
            <select id="inquiry-type" name="inquiry-type" required>
              <option value="">Select an option</option>
              <option value="catering">Catering Services</option>
              <option value="event-planning">Event Planning</option>
              <option value="feedback">Feedback</option>
              <option value="general">General Inquiry</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" rows="6" required></textarea>
          </div>
          <button type="submit" className="submit-button">Send Message</button>
        </form>
      </motion.div>
    </div>
  );
};

export default Contact;
