import React from 'react';
import '../Gallery.css';

const BirthdayGallery = () => {
  const images = [
    'path/to/birthday1.jpg',
    'path/to/birthday2.jpg',
    // Add more birthday images
  ];

  return (
    <>
    <div className="gallery-container">
      <h1>Birthday Gallery</h1>
      <div className="gallery-grid">
        {images.map((image, index) => (
          <div className="gallery-item" key={index}>
            <img src={image} alt={`Birthday ${index + 1}`} />
          </div>
        ))}
      </div>
    </div>
    </>
  );
};

export default BirthdayGallery;
