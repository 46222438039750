import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from 'react-helmet';
import './Events.css';
import images from '../../Components/Assests/Assests';

const occasions = [
  { 
    image: images.marriage, 
    title: 'Marriage Catering Services', 
    subtitle: 'Celebrate the union with elegance and joy.' 
  },
  { 
    image: 'https://www.orangeclove.com.sg/app/uploads/2024/02/Food-Catering-for-Your-Birthday-Party.jpg', 
    title: 'Birthday Party Catering', 
    subtitle: 'Make your special day unforgettable.' 
  },
  { 
    image: 'https://5.imimg.com/data5/SELLER/Default/2024/6/424831178/DH/GK/KR/139980869/corporate-catering-service-500x500.png', 
    title: 'Corporate Event Catering', 
    subtitle: 'Professional events with a touch of class.' 
  },
  { 
    image: 'https://brownbrotherscatering.com/wp-content/uploads/Tired-of-Hosting-Let-Us-Handle-the-Food-Catering-Near-You.jpg', 
    title: 'Family Gathering Catering', 
    subtitle: 'Cherish every moment with loved ones.' 
  },
  { 
    image: 'https://content.jdmagicbox.com/comp/nagpur/b3/0712px712.x712.170211120644.k9b3/catalogue/vijay-catering-services-uppalwadi-nagpur-caterers-6xf6z7.jpeg', 
    title: 'Festival Catering Services', 
    subtitle: 'Celebrate the vibrant colors of tradition.' 
  },
  { 
    image: 'https://4.imimg.com/data4/HS/VH/MY-15238607/buffet-services.jpg', 
    title: 'Anniversary Catering', 
    subtitle: 'Mark the milestones with memorable celebrations.' 
  },
];

const Events = () => {
  const [count, setCount] = useState(0);
  const [countStarted, setCountStarted] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const counterElement = document.querySelector('.counter-container-menu h1');
      const counterPosition = counterElement.getBoundingClientRect().top;
      const screenPosition = window.innerHeight;

      if (counterPosition < screenPosition && !countStarted) {
        startCounting();
        setCountStarted(true);
      }
    };

    const startCounting = () => {
      const target = 25;
      let countValue = 0;
      const speed = 50;

      const increment = () => {
        countValue += 1;
        setCount(countValue);
        if (countValue < target) {
          setTimeout(increment, speed);
        }
      };

      increment();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [countStarted]);

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <>
      <Helmet>
        <title>Events We Cover - Catering Services</title>
        <meta name="description" content="Explore our catering services for all events such as weddings, corporate gatherings, family events, and more. Choose us for your next special occasion." />
        <meta name="keywords" content="catering services, wedding catering, corporate event catering, family gathering catering, birthday party catering" />
        <meta property="og:title" content="Catering Services for Every Occasion" />
        <meta property="og:description" content="We provide top-tier catering services for weddings, corporate events, birthdays, and more. Experience elegance, flavor, and professionalism with our services." />
        <meta property="og:image" content="https://w7.pngwing.com/pngs/400/867/png-transparent-platter-seafood-mixed-grill-caucasian-cuisine-others-miscellaneous-food-seafood.png" /> {/* Replace with an actual image URL */}
        <meta property="og:url" content="https://www.ashapuracaterers.in/events" />
      </Helmet>

      <div className="occasion-wrapper">
        <img 
          src={images.specialleaf1} 
          alt="Leaf decoration for special events" 
          className='back-img-occaision'
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        />
        <img 
          src={images.specialleaf2} 
          alt="Decorative leaves for festive catering" 
          className='back-img-occaision2'
          data-aos="fade-left"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        />
        <img 
          src={images.specialleaf1} 
          alt="Leaf background for events" 
          className='back-img-occaision3'
          data-aos="fade-right"
          data-aos-offset="300"
          data-aos-easing="ease-in-sine"
        />

        <h1 className="occasion-title">Events We Cover</h1>
        <p className="occasion-subtitle">Choose from our tailored catering services to make your occasion special</p>

        <div className="occasion-cards">
          {occasions.map((occasion, index) => (
            <motion.div
              className="card occasion-card"
              key={index}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
            >
              <div className="card-image">
                <img src={occasion.image} alt={`${occasion.title} Catering Service`} />
              </div>
              <div className="card-overlay">
                <h3>{occasion.title}</h3>
                <p>{occasion.subtitle}</p>
              </div>
              <div className="card-subtitle">
                <p>{occasion.title}</p>
              </div>
            </motion.div>
          ))}
        </div>

        <h3>And Many More...</h3>
      </div>

      <div className="counter-container-menu">
        <h1>More than {count} years of experience</h1>
        <p>Celebrated Events, Crafted with Flavor</p>
      </div>
    </>
  );
};

export default Events;
