import React, { useRef, useEffect, useState, lazy, Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import "./Home.css";
import video from '../../Components/Assests/Assests';
import { useNavigate } from 'react-router-dom';

const SpecialMenu = lazy(() => import('../../Components/SpecialMenu/SpecialMenu'));
const Occaision = lazy(() => import('../../Components/Occaision/Occaision'));
const Reviews = lazy(() => import('../../Components/Reviews/Reviews'));

gsap.registerPlugin(ScrollTrigger);

const Home = () => {
  const videoRef = useRef(null);
  const leftTextRef = useRef(null);
  const rightContainerRef = useRef(null);
  const specialMenuRef = useRef(null);
  const [count, setCount] = useState(1);
  const [countStarted, setCountStarted] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      const counterElement = document.querySelector('.counter-container-menu h1');
      if (counterElement) {
        const counterPosition = counterElement.getBoundingClientRect().top;
        const screenPosition = window.innerHeight;

        if (counterPosition < screenPosition && !countStarted) {
          startCounting();
          setCountStarted(true);
        }
      }
    };

    const startCounting = () => {
      const target = 25;  
      let countValue = 1;  

      const speed = 50;

      const increment = () => {
        countValue += 1;
        setCount(countValue);
        if (countValue < target) {
          setTimeout(increment, speed);
        }
      };

      increment();
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [countStarted]);

  useEffect(() => {
    if (videoRef.current) {
      const videoElement = videoRef.current;

      const options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.1,
      };

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            videoElement.src = video.video1;
            videoElement.load();
            videoElement.currentTime = 2;
            videoElement.play().catch(error => console.error('Error playing video:', error));
            videoElement.loop = true;
            observer.unobserve(entry.target);
          }
        });
      }, options);

      observer.observe(videoElement);

      return () => {
        if (videoElement) {
          observer.unobserve(videoElement);
        }
      };
    }
  }, []);

  useEffect(() => {
    const leftTextChildren = leftTextRef.current ? leftTextRef.current.children : null;
    if (leftTextChildren) {
      gsap.fromTo(
        leftTextChildren,
        { opacity: 0, y: 50 },
        {
          opacity: 1,
          y: 0,
          stagger: 0.2,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: leftTextRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }

    if (rightContainerRef.current) {
      gsap.fromTo(
        rightContainerRef.current,
        { opacity: 0, scale: 0.8 },
        {
          opacity: 1,
          scale: 1,
          duration: 1,
          ease: "back.out(1.7)",
          scrollTrigger: {
            trigger: rightContainerRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse",
          },
        }
      );
    }

    gsap.fromTo(
      ".img-background-home img",
      { y: "-20%" },
      {
        y: "20%",
        ease: "none",
        scrollTrigger: {
          trigger: ".right-container-with-images",
          start: "top bottom",
          end: "bottom top",
          scrub: true,
        },
      }
    );

    if (specialMenuRef.current) {
      const topContainer = specialMenuRef.current.querySelector('.top-first-container-special');
      if (topContainer) {
        gsap.fromTo(
          topContainer,
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            duration: 1,
            scrollTrigger: {
              trigger: specialMenuRef.current,
              start: "top 80%",
              end: "bottom 20%",
              toggleActions: "play none none reverse",
            },
          }
        );
      }

      const cardContainer = specialMenuRef.current.querySelector('.card-container-click-handle-all-menu');
      if (cardContainer) {
        gsap.fromTo(
          cardContainer.querySelectorAll('.card-one-main'),
          { opacity: 0, y: 50 },
          {
            opacity: 1,
            y: 0,
            stagger: 0.2,
            duration: 1,
            scrollTrigger: {
              trigger: cardContainer,
              start: "top 80%",
              end: "bottom 20%",
              toggleActions: "play none none reverse",
            },
          }
        );
      }
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Catering Services | Delicious Event Meals</title>
        <meta name="description" content="We provide exceptional catering services for all occasions. Enjoy delicious meals crafted with care, perfect for your events." />
        <meta name="keywords" content="Catering services, event catering, delicious meals, special menu, food for events, reviews" />
        <link rel="canonical" href="https://ashapuracaterers.in/home" />
      </Helmet>

      <div className="home-container">
        <div className="main-circle-container-topleft">
          <div className="circle-one"></div>
          <div className="circle-two"></div>
          <div className="circle-three"></div>
        </div>

        <header className="header-container-home-page">
          <div ref={leftTextRef} className="left-text-container-home">
            <h1>We provide the best Catering Services for you</h1>
            <p>Delicious meals crafted with care, tailored for your perfect event.</p>
            <div className="two-buttons-left-container-home">
              <button onClick={() => navigate('/menu')}>Menu</button>
              <button onClick={() => navigate('/contact')}>Contact</button>
            </div>
          </div>

          <div ref={rightContainerRef} className="right-container-with-images">
            <div className="img-background-home">
              <img src={video.leafdesigns} alt="Leaf designs background" />
            </div>
            <div className="video-container">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                muted
                playsInline
                preload="none"
                className="video-home-page"
                aria-label="Catering Services Promotional Video"
              />
            </div>
          </div>
        </header>

        <div className="counter-container-menu">
        <h1>More than {count} years of experience</h1>
        <p>Celebrated Events, Crafted with Flavor</p>
      </div>

        <section ref={specialMenuRef}>
          <Suspense fallback={<div>Loading...</div>}>
            <SpecialMenu />
            <Occaision />
            <Reviews />
          </Suspense>
        </section>
      </div>
    </>
  );
};

export default Home;
