import React, { useState } from "react";
import "./Navbar.css";
import { Link, NavLink } from "react-router-dom";

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Function to close the menu after clicking a link
  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <div className="main-navbar-container">
        <div className="img-left-container">
          <img src="" alt="" />
          <h6>Ashapura Caterers</h6>
        </div>
        <div className={`central-container-navbar ${isMenuOpen ? "open" : ""}`}>
          <nav>
            <ul>
              <li>
                <NavLink
                  to="/"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={closeMenu} // Close the menu when a link is clicked
                >
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/menu"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={closeMenu} // Close the menu when a link is clicked
                >
                  Menu
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/events"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={closeMenu} // Close the menu when a link is clicked
                >
                  Events
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/gallery"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={closeMenu} // Close the menu when a link is clicked
                >
                  Gallery
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/about"
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={closeMenu} // Close the menu when a link is clicked
                >
                  About
                </NavLink>
              </li>
            </ul>
          </nav>
          <div className="mobile-contact-button">
            <button onClick={closeMenu}>Contact</button>
          </div>
        </div>
        <div className="right-container-navbar">
          <button> <Link to="/contact" >Contact</Link></button>
        </div>
        <div className="hamburger-container" onClick={toggleMenu}>
          <div className={`hamburger ${isMenuOpen ? "open" : ""}`}></div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
