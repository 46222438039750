import React from 'react';
import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import './Menu.css';

const Menu = () => {
  const platters = [
    {
      name: 'Paneer Platter',
      image: 'https://www.sanjanafeasts.co.uk/wp-content/uploads/2023/07/Delicious-Indian-Paneer-Tikka-restaurant-favourite-recipe-with-authentic-spices-683x1024.jpg',
      items: {
        appetizers: ['Paneer Tikka', 'Paneer Pakora'],
        mainCourse: ['Paneer Butter Masala', 'Shahi Paneer'],
        bread: ['Butter Naan', 'Tandoori Roti'],
        rice: ['Jeera Rice', 'Veg Biryani'],
        dessert: ['Gulab Jamun', 'Rasmalai'],
      },
    },
    {
      name: 'Punjabi Platter',
      image: 'https://i.pinimg.com/736x/df/4b/54/df4b54f1689acdfce039a984aa57bbbd.jpg',
      items: {
        appetizers: ['Chole Samosa', 'Aloo Tikki'],
        mainCourse: ['Chole Bhature', 'Sarson da Saag'],
        bread: ['Amritsari Kulcha', 'Makki di Roti'],
        rice: ['Pulao', 'Lemon Rice'],
        dessert: ['Jalebi', 'Rabri'],
      },
    },
    {
      name: 'Rajasthani Platter',
      image: 'https://images.jdmagicbox.com/quickquotes/listicle/listicle_1683805080606_35mfg_1040x500.jpg',
      items: {
        appetizers: ['Kachori', 'Mirchi Vada'],
        mainCourse: ['Dal Baati Churma', 'Gatte ki Sabzi'],
        bread: ['Bajra Roti', 'Missi Roti'],
        rice: ['Ghee Rice', 'Khichdi'],
        dessert: ['Malpua', 'Mawa Kachori'],
      },
    },
    {
      name: 'South Indian Platter',
      image: 'https://i.pinimg.com/736x/68/3f/27/683f2784d71e50fda2673d69f5c0ea54.jpg',
      items: {
        appetizers: ['Medu Vada', 'Mini Idli'],
        mainCourse: ['Masala Dosa', 'Uttapam'],
        bread: ['Chapati', 'Parotta'],
        rice: ['Curd Rice', 'Lemon Rice'],
        dessert: ['Payasam', 'Mysore Pak'],
      },
    },
  ];

  return (
    <>
      <Helmet>
        <title>Best Indian Food Platters | Menu</title>
        <meta
          name="description"
          content="Explore the best Indian platters including Paneer Platter, Punjabi Platter, Rajasthani Platter, and South Indian Platter. Customize your dish from appetizers to desserts."
        />
        <meta
          name="keywords"
          content="Indian food, Paneer Platter, Punjabi Platter, Rajasthani Platter, South Indian Platter, Indian cuisine, appetizers, main course, desserts"
        />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://ashapuracaterers.in/menu" />
      </Helmet>

      <div className="main-menu-container-page">
        <motion.div
          className="top-container-menu"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1>Customize your dish with the menu</h1>
          <h6>Click Here to view or download the menu pdf</h6>
          <a
            href="https://drive.google.com/file/d/1d7x5YNeht84nptyQAvT7GIg2_kjuq6v1/view?usp=drive_link"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Menu
          </a>
          <h1>Our Best Platters</h1>
        </motion.div>

        <div className="top-buffet-platter">
          {platters.map((platter, index) => (
            <motion.div
              className="platter-container"
              key={index}
              initial={{ opacity: 0, x: -50 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.6, delay: index * 0.2 }}
            >
              <img
                src={platter.image}
                alt={platter.name}
                className="platter-image"
              />
              <div className="platter-details">
                <h2 className="platter-name">{platter.name}</h2>

                <h3>Appetizers:</h3>
                <ul>
                  {platter.items.appetizers.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h3>Main Course:</h3>
                <ul>
                  {platter.items.mainCourse.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h3>Bread:</h3>
                <ul>
                  {platter.items.bread.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h3>Rice:</h3>
                <ul>
                  {platter.items.rice.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>

                <h3>Dessert:</h3>
                <ul>
                  {platter.items.dessert.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </ul>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Menu;
