import React from 'react';
import { motion } from 'framer-motion';
import { Helmet } from 'react-helmet';
import './About.css';

const About = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
 <Helmet>
        <title>About Us - Ashapura Caterers | Leading Caterers in Mumbai, Thane, Bhiwandi</title>
        <meta name="description" content="Learn more about Ashapura Caterers, offering professional catering services for events in Mumbai, Thane, and Bhiwandi." />
        <meta name="keywords" content="catering services Mumbai, Thane, Bhiwandi, wedding catering, corporate event catering" />

        <script type="application/ld+json">
          {`
          {
            "@context": "http://schema.org",
            "@type": "LocalBusiness",
            "name": "Ashapura Caterers and Services",
            "description": "Top catering services in Mumbai, Thane, and Bhiwandi specializing in weddings, corporate events, and parties.",
            "image": "https://.com/logo.png",
            "address": {
              "@type": "PostalAddress",
              "streetAddress": " sangampada road",
              "addressLocality": "Bhiwandi",
              "addressRegion": "Maharashtra",
              "postalCode": "421302",
              "addressCountry": "IN"
            },
            "telephone": "+91-8177804106",
            "url": "https://ashapuracaterers.in",
            "email": "info@ashapuracaterers.com",
            "openingHours": "Mo,Tu,We,Th,Fr 09:00-20:00",
            "priceRange": "n/a",
            "geo": {
              "@type": "GeoCoordinates",
              "latitude": "19.2813",
              "longitude": "73.0483"
            },
            "areaServed": {
              "@type": "Place",
              "name": ["Mumbai", "Thane", "Bhiwandi"]
            }
          }
          `}
        </script>
      </Helmet>
      <motion.div
        className="about-container"
        variants={containerVariants}
        initial="hidden"
        animate="visible"
      >
        <motion.div className="about-content" variants={itemVariants}>
          <motion.h1 
            className="about-title"
            initial={{ scale: 0.5, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          >
            Ashapura Caterers and Services: Crafting Culinary Memories
          </motion.h1>

          <motion.p className="about-description" variants={itemVariants}>
            For over <span className="highlight">25 years</span>, Ashapura Caterers and Services has been the cornerstone of exquisite taste and unparalleled service in Bhiwandi and beyond. Our journey began with a simple passion for food and has blossomed into a legacy of culinary excellence.
          </motion.p>

          <motion.p className="about-description" variants={itemVariants}>
            From intimate gatherings of 50 to grand celebrations of <span className="highlight">80,000</span>, we've mastered the art of scaling our services without compromising on quality. Our versatility is our strength, allowing us to cater to weddings, corporate events, festivals, and everything in between with equal finesse.
          </motion.p>

          <motion.p className="about-description" variants={itemVariants}>
            At the heart of our success lies our unwavering commitment to <span className="highlight">quality ingredients</span>. We believe that great food starts with the finest produce, and we go to great lengths to source the best. Our chefs, each a master in their cuisine, transform these ingredients into culinary masterpieces that tantalize the taste buds and warm the soul.
          </motion.p>

          <motion.p className="about-description" variants={itemVariants}>
            But Ashapura is more than just about food. We're about creating <span className="highlight">experiences</span>. Our team of event planners, decorators, and service staff work in harmony to ensure that every aspect of your event is perfect. From the initial consultation to the final toast, we're with you every step of the way.
          </motion.p>

          <motion.p className="about-description" variants={itemVariants}>
            As we look to the future, we're excited to blend tradition with innovation. We're constantly exploring new cuisines, techniques, and presentation styles to keep our offerings fresh and exciting. Yet, we never forget our roots and the timeless recipes that have made us who we are.
          </motion.p>

          <motion.p className="about-description" variants={itemVariants}>
            Choose Ashapura Caterers and Services for your next event, and let us weave our magic to create memories that will last a lifetime. After all, life's most beautiful moments deserve to be celebrated with equally beautiful food.
          </motion.p>

          <motion.div 
            className="cta-container"
            initial={{ scale: 0.8, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ delay: 1.5, duration: 0.5 }}
          >
            <a href="/contact" className="cta-button">Get in Touch</a>
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default About;
