import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import { FaPhone } from 'react-icons/fa';
import { motion } from 'framer-motion';
import Home from './pages/Home/Home';
import Navbar from './Components/Navbar/Navbar';
import Footer from './Components/Footer/Footer';
import Events from './pages/Events/Events';
import Menu from './pages/Menu/Menu';
import Gallery from './pages/Gallery/Gallery';
import Preloader from './Components/Preloader/Preloader';
import About from './pages/About/About';
import Contact from './pages/Contact/Contact';
import images from './Components/Assests/Assests';
import BirthdayGallery from './pages/Gallery/BirthdayGallery/BirthdayGallery';
import Helmet from 'react-helmet';
const steps = [
  {
    id: '0',
    message: 'Welcome to our Catering Service!',
    trigger: '1',
  },
  {
    id: '1',
    message: 'Please enter your name:',
    trigger: '2',
  },
  {
    id: '2',
    user: true,
    trigger: '3',
  },
  {
    id: '3',
    message: 'Hi {previousValue}, how can I assist you with our catering services today?',
    trigger: '4',
  },
  {
    id: '4',
    options: [
      { value: 1, label: 'View Vegetarian Menu', trigger: '5' },
      { value: 2, label: 'Request a Quote', trigger: '6' },
      { value: 3, label: 'Learn About Us', trigger: '7' },
      { value: 4, label: 'Check Event Availability', trigger: '10' },
    ],
  },
  {
    id: '5',
    message: 'Our vegetarian menu includes a variety of salads, appetizers, and main courses. Would you like to know more?',
    trigger: 'vegetarian-confirm',
  },
  {
    id: 'vegetarian-confirm',
    options: [
      { value: 'yes', label: 'Yes, show me!', trigger: 'vegetarian-details' },
      { value: 'no', label: 'No, I’m good.', trigger: '8' },
    ],
  },
  {
    id: 'vegetarian-details',
    message: 'Our vegetarian dishes include fresh salads, soups, and a variety of mains. Visit our Menu page for full details!',
    trigger: '8',
  },
  {
    id: '6',
    message: 'Please provide details about your event, including the date, location, and number of guests.',
    trigger: 'event-details',
  },
  {
    id: 'event-details',
    user: true,
    trigger: 'quote-response',
  },
  {
    id: 'quote-response',
    message: 'Thank you! Our team will reach out with a personalized quote soon. Can I help you with anything else?',
    trigger: '8',
  },
  {
    id: '7',
    message: 'We are a leading catering service focused on quality and customer satisfaction. Would you like to hear from our happy clients?',
    trigger: 'about-confirm',
  },
  {
    id: 'about-confirm',
    options: [
      { value: 'yes', label: 'Yes, tell me more!', trigger: 'client-testimonials' },
      { value: 'no', label: 'No, that’s all for now.', trigger: '8' },
    ],
  },
  {
    id: 'client-testimonials',
    message: 'Our clients love us for our attention to detail and amazing food! Visit our About page to learn more.',
    trigger: '8',
  },
  {
    id: '10',
    message: 'Please enter the date of your event to check availability:',
    trigger: 'event-date',
  },
  {
    id: 'event-date',
    user: true,
    trigger: 'event-availability',
  },
  {
    id: 'event-availability',
    message: 'Thank you! We will check the availability for {previousValue} and get back to you shortly.',
    trigger: '8',
  },
  {
    id: '8',
    message: 'Is there anything else I can help you with?',
    trigger: '9',
  },
  {
    id: '9',
    options: [
      { value: 1, label: 'Yes', trigger: '4' },
      { value: 2, label: 'No', trigger: '10' },
    ],
  },
  {
    id: '10',
    message: 'Thank you for visiting us! Have a great day!',
    end: true,
  },
];

const theme = {
  background: '#FFA500',
  headerBgColor: '#FF8C00',
  headerFontSize: '20px',
  botBubbleColor: '#FF8C00',
  headerFontColor: 'black',
  botFontColor: 'black',
  userBubbleColor: '#FFD700',
  userFontColor: 'black',
};

const config = {
  botAvatar: images.chefbotimg,
  floating: true,
  enableSmoothScroll: true,
};

const pageTransition = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: '-100%',
  },
};

const App = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleLoaded = () => {
    setIsLoaded(true);
    setLoading(false);
  };

  return (
    <Router>
        <Helmet>
          <title>Catering Services in Mumbai, Thane & Bhiwandi | Ashapura Caterers</title>
          <meta name="description" content="Ashapura Caterers offers top-tier catering services in Mumbai, Thane, and Bhiwandi for weddings, corporate events, and parties." />
          <meta name="keywords" content="catering services Mumbai, wedding caterers Thane, event catering Bhiwandi" />
          <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </Helmet>

      {isLoaded && <Navbar />}
      <Routes>
        <Route
          path="/"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <Home onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route
          path="/events"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <Events onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route
          path="/menu"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <Menu onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route
          path="/gallery"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <Gallery onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route
          path="/about"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <About onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route
          path="/contact"
          element={
            <motion.div initial="out" animate="in" exit="out" variants={pageTransition}>
              {loading && <Preloader onLoaded={handleLoaded} />}
              {!loading && <Contact onLoad={() => setLoading(true)} />}
            </motion.div>
          }
        />
        <Route path="/gallery/birthday" element={<BirthdayGallery />} />
      </Routes>
      {isLoaded && <Footer />}
      <ThemeProvider theme={theme}>
        <ChatBot
          headerTitle="Catering Bot"
          steps={steps}
          {...config}
          style={{
            position: 'fixed',
            bottom: '100px',
            right: '30px',
            zIndex: 1000,
            // Responsive adjustments
            maxWidth: '350px',
            width: '90%', // Adjust width for smaller devices
          }}
        />
      </ThemeProvider>

      {/* Call Icon */}
      <a
        href="tel:8177804106"
        style={{
          position: 'fixed',
          bottom: '100px',
          right: '30px',
          backgroundColor: '#FF8C00',
          borderRadius: '50%',
          padding: '15px',
          zIndex: '1',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
          cursor: 'pointer',
        }}
      >
        <FaPhone color="black" size={24} />
      </a>
    </Router>
  );
};

export default App;