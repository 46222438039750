import React, { useRef, useEffect, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './MenuSlider.css';
import imagesgallery from '../Assests/Assests';

const MenuSlider = () => {
  const carouselRef = useRef(null);
  const [isDragStart, setIsDragStart] = useState(false);
  const [isDragging, setIsDragging] = useState(false);
  const [prevPageX, setPrevPageX] = useState(0);
  const [prevScrollLeft, setPrevScrollLeft] = useState(0);
  const [positionDiff, setPositionDiff] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const imageSources = [
    imagesgallery.galleryimg1,  // Add the correct paths for your images here
    imagesgallery.galleryimg2,
    imagesgallery.galleryimg3,
    imagesgallery.galleryimg4,
    imagesgallery.galleryimg5,
    imagesgallery.galleryimg6,
    imagesgallery.galleryimg7,
    imagesgallery.galleryimg8,
    imagesgallery.galleryimg9,
  ];

  useEffect(() => {
    const showHideIcons = () => {
      if (carouselRef.current) {
        let scrollWidth = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
        setShowLeftArrow(carouselRef.current.scrollLeft > 0);
        setShowRightArrow(carouselRef.current.scrollLeft < scrollWidth);
      }
    };

    const autoSlide = () => {
      if (!carouselRef.current) return;

      if (
        carouselRef.current.scrollLeft - (carouselRef.current.scrollWidth - carouselRef.current.clientWidth) > -1 ||
        carouselRef.current.scrollLeft <= 0
      )
        return;

      const firstImg = carouselRef.current.querySelectorAll("img")[0];
      if (!firstImg) return;

      let absPositionDiff = Math.abs(positionDiff);
      let firstImgWidth = firstImg.clientWidth + 14;
      let valDifference = firstImgWidth - absPositionDiff;

      if (carouselRef.current.scrollLeft > prevScrollLeft) {
        carouselRef.current.scrollLeft += absPositionDiff > firstImgWidth / 3 ? valDifference : -absPositionDiff;
      } else {
        carouselRef.current.scrollLeft -= absPositionDiff > firstImgWidth / 3 ? valDifference : -absPositionDiff;
      }
    };

    const dragStart = (e) => {
      setIsDragStart(true);
      setPrevPageX(e.pageX || (e.touches && e.touches[0].pageX));
      setPrevScrollLeft(carouselRef.current ? carouselRef.current.scrollLeft : 0);
    };

    const dragging = (e) => {
      if (!isDragStart) return;
      e.preventDefault();
      setIsDragging(true);
      if (carouselRef.current) {
        carouselRef.current.classList.add("dragging");
        let posDiff = (e.pageX || (e.touches && e.touches[0].pageX)) - prevPageX;
        carouselRef.current.scrollLeft = prevScrollLeft - posDiff;
        setPositionDiff(posDiff);
        showHideIcons();
      }
    };

    const dragStop = () => {
      setIsDragStart(false);
      if (carouselRef.current) {
        carouselRef.current.classList.remove("dragging");
      }

      if (!isDragging) return;
      setIsDragging(false);
      autoSlide();
    };

    if (carouselRef.current) {
      carouselRef.current.addEventListener("mousedown", dragStart);
      carouselRef.current.addEventListener("touchstart", dragStart);
      document.addEventListener("mousemove", dragging);
      carouselRef.current.addEventListener("touchmove", dragging);
      document.addEventListener("mouseup", dragStop);
      carouselRef.current.addEventListener("touchend", dragStop);
    }

    return () => {
      if (carouselRef.current) {
        carouselRef.current.removeEventListener("mousedown", dragStart);
        carouselRef.current.removeEventListener("touchstart", dragStart);
        document.removeEventListener("mousemove", dragging);
        carouselRef.current.removeEventListener("touchmove", dragging);
        document.removeEventListener("mouseup", dragStop);
        carouselRef.current.removeEventListener("touchend", dragStop);
      }
    };
  }, [isDragStart, isDragging, prevPageX, prevScrollLeft, positionDiff]);

  const handleArrowClick = (direction) => {
    if (!carouselRef.current) return;

    const firstImg = carouselRef.current.querySelectorAll("img")[0];
    if (!firstImg) return;

    const firstImgWidth = firstImg.clientWidth + 14;
    carouselRef.current.scrollLeft += direction === "left" ? -firstImgWidth : firstImgWidth;
    setTimeout(() => {
      let scrollWidth = carouselRef.current.scrollWidth - carouselRef.current.clientWidth;
      setShowLeftArrow(carouselRef.current.scrollLeft > 0);
      setShowRightArrow(carouselRef.current.scrollLeft < scrollWidth);
    }, 60);
  };

  return (
    <div className="main-container-div-menuslider">
      <motion.div 
        className="wrapper"
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <AnimatePresence>
          {showLeftArrow && (
            <motion.i
              className="fa-solid fa-angle-left"
              onClick={() => handleArrowClick("left")}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -20 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </AnimatePresence>
        <motion.div 
          className="carousel" 
          ref={carouselRef}
          whileTap={{ cursor: "grabbing" }}
        >
          {imageSources.map((src, index) => (
            <motion.img
              key={index}
              src={src}
              alt={`img-${index + 1}`}
              draggable="false"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.2 }}
            />
          ))}
        </motion.div>
        <AnimatePresence>
          {showRightArrow && (
            <motion.i
              className="fa-solid fa-angle-right"
              onClick={() => handleArrowClick("right")}
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{ duration: 0.3 }}
            />
          )}
        </AnimatePresence>
      </motion.div>
    </div>
  );
};

export default MenuSlider;
